import React,{useState, useEffect, useRef} from 'react';
import clsx from 'clsx';
import {doGet, doPost, doDelete, doPatch} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {productsDummy, categoriesDummy} from 'utils/dummy'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import DebouncedTextField from 'components/DebouncedTextField.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Pill from 'components/Pill';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddStock from './AddStock';
import ReduceStock from './ReduceStock';
import CategoryForm from './CategoryForm';
import ProductForm from './ProductForm';
import MoreVert from '@material-ui/icons/MoreVert';
import StarOutline from '@material-ui/icons/StarOutline';
import Star from '@material-ui/icons/Star';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteConfirmation from 'components/DeleteConfirmation.js';
import Visibility from '@material-ui/icons/Visibility';
import numeral from 'numeral'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import { generalListOptionMapper } from 'utils/mappers.js';



const AdminProduct =(props)=> {
  
  let { path, url } = useRouteMatch();
  let history = useHistory();
  const classes = useStyles();
  const searchProductRef = useRef(null);
  const user = useSelector(state => state.user);
  const [categories, setcategories] = useState([]);
  const [products, setproducts] = useState([]);
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogWidth, setdialogWidth] = useState('sm');
  const [dialogContent, setdialogContent] = useState(null);
  const [dialogTitle, setdialogTitle] = useState('title');
  const [selectedCategory, setselectedCategory] = useState(null);
  const [selectedProduct, setselectedProduct] = useState(null);
  const [isSearchCategory, setisSearchCategory] = useState(false);
  const [isSearchProduct, setisSearchProduct] = useState(false);
  const [menuCategoryAnchor, setmenuCategoryAnchor] = React.useState(null);
  const [menuProductAnchor, setmenuProductAnchor] = React.useState(null);

  
  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
    
  }
  
  const back =()=>{
    history.goBack()
  }

  const getCategories =async()=>{
    const params={}
    const response = await doGet(endpoint.category, params, getDefaultHeader());        
    if(!response.error){
      setcategories(response.data)
    }
    
  } 
  
  const getProducts =async(categoryParam)=>{
    let category = null
    if(categoryParam){
      category = categoryParam
    }else if(selectedCategory){
      category = selectedCategory
    }

    if(category){
      const params={
        type:'admin',
        category_id:category.id
      }
          
      const response = await doGet(endpoint.product, params, getDefaultHeader());        
      if(!response.error){
        setproducts(
          response.data.map(product=>({
            ...product,
            stocks:product.stocks.map(stock=>({
              ...stock, 
              stock:parseInt(stock.stock)
              })),
            jenjangs:generalListOptionMapper(product.jenjangs.map(jenjang=>({
              ...jenjang, 
              id:jenjang.stage_id,
              name:jenjang.stage_name
              }))),
            kelases:generalListOptionMapper(product.kelases.map(kelas=>({
              ...kelas, 
              id:kelas.class_id,
              name:kelas.class_name,
              stage_id:kelas.stage_id
              })))
          }))
        )      
      }
    }

    
        
  } 

  const showAddStock =()=>{
    setdialogTitle('Tambah Stok')
    setdialogContent(
    <AddStock 
    product={selectedProduct} 
    getProducts={getProducts}
    closeDialog={closeDialog}
    user={user}
    />)
    setdialogWidth('md')
    setopenDialog(true)
  }
  
  const showReduceStock =()=>{
    setdialogTitle('Kurangi Stok')
    setdialogContent(
    <ReduceStock 
    product={selectedProduct}
    getProducts={getProducts}
    closeDialog={closeDialog}
    user={user}
    />)
    setdialogWidth('md')
    setopenDialog(true)
  }

  const closeDialog =()=>{    
    setopenDialog(false)
  }

  const btnAddCategory =()=>{    
    setdialogTitle('Tambah Kategori')
    setdialogContent(<CategoryForm user={user} getCategories={getCategories} handleClose={closeDialog} actionType="add"/>)
    setdialogWidth('sm')
    setopenDialog(true)
    
  }

  const openEditCategoryForm =()=>{    
    setmenuCategoryAnchor(null)
    setdialogTitle('Ubah Kategori')
    setdialogContent(<CategoryForm user={user} getCategories={getCategories} category={selectedCategory} handleClose={closeDialog} actionType="edit"/>)
    setdialogWidth('sm')
    setopenDialog(true)
    
  }

  const deleteCategoryConfirmation =()=>{    
    setmenuCategoryAnchor(null)
    setopenDialog(true)
    setdialogWidth('sm')
    setdialogTitle("Hapus Kategori")
    setdialogContent(<DeleteConfirmation handleDelete={deleteCategory} handleClose={closeDialog} />)
    
  }

  const deleteProductConfirmation =(e)=>{    
    e.stopPropagation();
    setopenDialog(true)
    setdialogWidth('sm')
    setdialogTitle("Hapus Produk")
    setdialogContent(<DeleteConfirmation handleDelete={deleteProduct} handleClose={closeDialog} />)    
  }

  const deleteCategory =async()=>{        
    const response = await doDelete(`${endpoint.category}/${selectedCategory.id}`, {},"delete category",getDefaultHeader())
    closeMenuCategory()
    getCategories()
  }

  const deleteProduct =async()=>{        
    const response = await doDelete(`${endpoint.product}/${selectedProduct.id}`, {},"delete product",getDefaultHeader())
    closeMenuProduct()
    getProducts(selectedCategory)
  }

  const btnAddProduct =()=>{    
    setdialogTitle('Tambah Produk')
    setdialogContent(<ProductForm categories={categories} category={selectedCategory} getProducts={getProducts} handleClose={closeDialog} actionType="add"/>)
    setdialogWidth('lg')
    setopenDialog(true)
    
  }

  const btnSearchCategory =()=>{    
    setisSearchCategory(!isSearchCategory)
    
  }

  const btnSearchProduct =()=>{           
    setisSearchProduct(!isSearchProduct)    
  }
  

  const categoryItemClick=(category)=>{
    setselectedCategory(category)
    getProducts(category)
  }
  
  const productItemClick=(product)=>{
    setselectedProduct(product)    
  }

  const btnDetailClick=(product)=>{
    setselectedProduct(product)
    setdialogTitle('Ubah Produk')
    setdialogContent(<ProductForm categories={categories} category={selectedCategory} getProducts={getProducts} product={product} handleClose={closeDialog} actionType="edit"/>)
    setdialogWidth('lg')
    setopenDialog(true)
  }

  const btnMenuCategory = (event) => {
    setmenuCategoryAnchor(event.currentTarget);
  };

  const btnMenuProduct = (event) => {
    setmenuProductAnchor(event.currentTarget);
  };

  const closeMenuCategory = () => {
    setmenuCategoryAnchor(null);
  };

  const closeMenuProduct = () => {
    setmenuProductAnchor(null);
  };

  const getProductPrice =(product)=>{    
    if(product.sizeType===1){
      return numeral(product.prices[0].price).format('0,0')
    }else{
      return numeral(product.prices[0].price).format('0,0') +" - "+ numeral(product.prices[product.prices.length - 1].price).format('0,0')
    }
  }

  const toggleMainCategory = async(category) => {
    let payload = {
      id:category.id,
      isMain: category.isMain===0 ? 1 : 0
    }
    await doPatch(endpoint.category, payload,"update category",getDefaultHeader())    
    getCategories()
  };

  const filterCategory = (keyword) => {
    if(keyword.trim()!==""){
      setcategories(categories.filter(category=> category.name.toLowerCase().includes(keyword.toLowerCase())))
    }else{
      getCategories()
    }
    
  };

  const filterProduct = (keyword) => {
    if(keyword.trim()!==""){
      setproducts(products.filter(product=> product.name.toLowerCase().includes(keyword.toLowerCase())))
    }else{
      getProducts(selectedCategory)
    }
    
  };
  

  useEffect(() => {
      getCategories()    
    
  }, [])  
  

  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            <Grid container item xs={11} justify="flex-end" alignItems="center" >                                    
                {
                    selectedProduct && 
                    <>
                    <Button  onClick={showReduceStock} variant="contained" color="secondary" style={{margin:'0 4px'}}>
                        Kurangi Stok    
                    </Button>
                    <Button  onClick={showAddStock} variant="contained" color="primary" style={{margin:'0 4px'}}>
                        Tambah Stok    
                    </Button>           
                    </>
                }
                     
            </Grid>          
            <Grid container justify="center" style={{height:'92%'}}>
                <Grid container item alignItems="flex-start" xs={12} md={4} className={classes.left}>
                    <Grid container alignItems="center" style={{padding:8}}>                        
                        <Grid container item xs={6}>
                            <Typography variant="h6">Categories</Typography>   
                        </Grid>                     
                        <Grid container item xs={6} justify="flex-end" alignItems="center" >
                            <IconButton onClick={btnSearchCategory} color="primary" aria-label="go back" component="span" >
                                <Search />
                            </IconButton>
                            <IconButton onClick={btnAddCategory} color="primary" aria-label="go back" component="span">
                                <Add />
                            </IconButton>
                            <IconButton onClick={btnMenuCategory} color="primary" aria-label="go back" component="span">
                                <MoreVert />
                            </IconButton>
                        </Grid>                     
                        
                        {
                            isSearchCategory && 
                            <Grid container >
                                <DebouncedTextField
                                    
                                    margin="dense"
                                    id="Name"
                                    placeholder="search"                                                                                   
                                    fullWidth
                                    variant="outlined"
                                    value={""}
                                    onChange={filterCategory}
                                />
                            </Grid>   
                        }
                        <Divider style={{width:'100%', marginTop:8}}/>

                        <Grid container>                        
                            <List component="nav" aria-label="categories" style={{width:'100%'}}>
                                {
                                    categories.map(category=>(
                                        <>
                                        <ListItem 
                                        selected={selectedCategory && selectedCategory.id===category.id} 
                                        button 
                                        onClick={()=>categoryItemClick(category)}
                                        >
                                            <ListItemText primary={category.name} />
                                            <ListItemSecondaryAction>
                                              <Tooltip title="Main Category">
                                                <IconButton onClick={()=>toggleMainCategory(category)} edge="end" aria-label="delete">
                                                  {
                                                    category.isMain===1 && <Star  style={{color:"#ffa90d"}}/>
                                                  }
                                                  {
                                                    category.isMain===0 && <StarOutline/>
                                                  }
                                                </IconButton>
                                              </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                        </>
                                    ))
                                }
                                
                            </List>
                        </Grid>                     
                    </Grid>
                </Grid>

                <Grid container item xs={12} md={7} alignItems="flex-start" className={classes.right}>
                <Grid container alignItems="center" style={{padding:8}}>                        
                        <Grid container item xs={2}>
                            <Typography variant="h6">Products</Typography>   
                            
                        </Grid>    
                        <Grid container item xs={7} >
                            {
                                isSearchProduct &&
                                <DebouncedTextField
                                inputRef = {searchProductRef}
                                margin="dense"
                                id="Name"
                                placeholder="search"                                                                                   
                                fullWidth
                                variant="outlined"
                                value={""}
                                onChange={filterProduct}
                            />
                            }
                            
                        </Grid>                  
                        <Grid container item xs={3} justify="flex-end" alignItems="center" >
                            <IconButton onClick={btnSearchProduct} color="primary" aria-label="go back" component="span" >
                                <Search />
                            </IconButton>
                            <IconButton onClick={btnAddProduct} color="primary" aria-label="go back" component="span" >
                                <Add />
                            </IconButton>
                            <IconButton onClick={btnMenuProduct} color="primary" aria-label="go back" component="span">
                                <MoreVert />
                            </IconButton>
                        </Grid>  

                        <Grid container> 
                        <div style={{ overflowY: 'auto', maxHeight: '400px', width: '100%' }}>

                        {
                        products && products.map(product=>(
                            
                            <Paper  elevation={2} 
                            className={clsx({
                              [classes.productItem]:!selectedProduct || selectedProduct.id!==product.id, 
                              [classes.selectedProductItem]:selectedProduct && selectedProduct.id===product.id})}
                            >
                            <Grid container item xs={12} onClick={()=>productItemClick(product)}>                               
                                
                                <Grid container item xs={2} justify="center" alignItems="center" >                                    
                                    <img src={product.cover} width="100" height="100" style={{width:'100%', minHeight:100, height:'auto'}}/>
                                </Grid>
                                
                                <Grid container item xs={10}>
                                    <Grid container item xs={6} justify="flex-start" style={{padding:'0 0 0 8px' }}>
                                        <Typography variant="h5">
                                            {
                                            product.name
                                            }
                                        </Typography>                                
                                    </Grid>
                                     
                                    <Grid container alignItems="center" item xs={12} style={{padding:'0 0 0 8px' }}>
                                        {
                                          product.stocks.map(stock=>(
                                            <Pill label={stock.size.size_label} value={stock.stock}/>
                                          ))
                                        }                                                                                
                                    </Grid>  

                                    <Grid container alignItems="center" style={{padding:'0 0 0 8px' }}>
                                        <Grid container item xs={9}>
                                            <Typography variant="h6">
                                                Rp. {
                                                    getProductPrice(product)
                                                }                          
                                            </Typography>
                                        </Grid>                                        

                                        <Grid container item xs={3} justify="flex-end">
                                            <IconButton
                                                onClick={(e)=>btnDetailClick(product)}
                                                
                                                aria-label="add"
                                                style={{margin:0}}>
                                                <Visibility fontSize="small" />
                                            </IconButton>                                            
                                        </Grid>                                        
                                    </Grid>                                        
                                </Grid>    
                                       
                                
                            </Grid>
                            </Paper>  
                                            
                        ))
                        }
                        </div>
                        </Grid>
                        
                                               
                    </Grid>
                </Grid>
            </Grid>
            
        </Grid>

        <Dialog
        fullWidth={true}
        maxWidth={dialogWidth}
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
        </Dialog>

        <Menu
        id="category-menu"
        anchorEl={menuCategoryAnchor}
        keepMounted
        open={Boolean(menuCategoryAnchor)}
        onClose={closeMenuCategory}
        >
            <MenuItem onClick={openEditCategoryForm}>Edit kategori</MenuItem>        
            <MenuItem onClick={deleteCategoryConfirmation}>Hapus kategori</MenuItem>        
        </Menu>

        <Menu
        id="category-menu"
        anchorEl={menuProductAnchor}
        keepMounted
        open={Boolean(menuProductAnchor)}
        onClose={closeMenuProduct}
        >
            <MenuItem onClick={deleteProductConfirmation}>Hapus produk</MenuItem>        
        </Menu>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },  
    marginH4:{
      marginLeft:4,
      marginRight:4,
    },  
    root: {
      padding: theme.spacing(1)
    },    
    left:{      
        background:'white'  ,
        padding:'0 8px',
        border:'1px solid #e5e5e5',
        borderRadius:4,
        height:'auto',
        marginRight:16
    },
    right:{
        background:'white'  ,
        padding:'0 8px',     
        border:'1px solid #e5e5e5',
        borderRadius:4   ,
        height:'auto'
    },
    productItem:{
        width:'100%', 
        margin:'8px 0', 
        padding:8,
        cursor:'pointer'
    },
    selectedProductItem:{
      width:'100%', 
        margin:'8px 0', 
        padding:8,
        cursor:'pointer',
        background:'#ebebeb'
    }
    
  }));


export default AdminProduct;
