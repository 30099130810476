import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {cartDummy} from 'utils/dummy'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import DebouncedTextField from 'components/DebouncedTextField.js';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Pill from 'components/Pill';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format } from 'date-fns'
import Select from 'react-select'
import {selectCustomZindex} from 'utils/theme'
import numeral from 'numeral'
import Confirmation from 'components/Confirmation.js';


const NoteForm = (props)=>{
  const {order, handleClose, onAction}=props
  const [state, setstate] = useState(null);
  
  const noteChange=(eventValue)=>{
    let value = eventValue.target.value
    setstate({
      ...state,
      note:value
    })    
  }

  const submit =()=> {
    onAction(state,'note')
    handleClose()
  }

  useEffect(() => {
    if(order){
      setstate(order[0])
    }
    
  }, [order])

  if(state){
    return(
      <>
        <DialogContent>
          <DialogContentText>{state && state.noteIntruction}</DialogContentText>
          <Grid container item xs={12}>
            <TextField
                id="Reason"
                label="Catatan"
                multiline
                rows={4}
                defaultValue=""
                variant="outlined"
                fullWidth
                value={state.note}
                onChange={noteChange}
              />
          </Grid>              
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleClose} color="secondary">
          Batal
          </Button>
          <Button variant="contained" onClick={submit} color="primary">
          Simpan
          </Button>
        </DialogActions>
      </>
    )
  }else{
    return null
  }
  
}

export default NoteForm;