import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { format } from 'date-fns'
import Conditional from 'components/Conditional';
import Protected from 'components/Protected';
import TextField from '@material-ui/core/TextField';
import ApprovalChip from 'components/ApprovalChip';
import ApprovalForm from './ApprovalForm';


const ApprovalDetail =(props)=> {
  const {handleClose, user, selectedApproval, getapprovals} = props
  const classes = useStyles();
  const [openDialog, setopenDialog] = React.useState(false);
  const [dialogContent, setdialogContent] = useState(null);
  const [state, setstate] = useState(null);

  const getDefaultHeader =()=>{
    return {"Authorization":`Bearer ${user.token}`}
  }

 
  const closeDialog =()=>{    
    setopenDialog(false)
  }

  const handleChange=(fieldName,eventValue)=>{
    let newState = {...state}
    let value = eventValue.target.value
    
    newState[fieldName] = value
    setstate(newState)
  }

  const rejectSubmit =async()=>{    
    
    const payload = {
      id:state.id,
      status:2
    }
    const response = await doPost(endpoint.approval, payload,"reject approval",getDefaultHeader())
    
    if(response && !response.error){
      getapprovals()
      handleClose()
    }
  }

  const rejectApproval =async(reason)=>{    
    
    const payload = {
      id:state.id,
      status:2,
      reason:reason
    }
    const response = await doPost(endpoint.approval, payload,"reject approval",getDefaultHeader())
    
    if(response && !response.error){
      getapprovals()
      handleClose()
    }
  }

  const approveApproval =async(reason)=>{    
    
    const payload = {
      id:state.id,
      status:1,
      reason:reason
    }
    const response = await doPost(endpoint.approval, payload,"approve approval",getDefaultHeader())
    
    if(response && !response.error){
      getapprovals()
      handleClose()
    }
  }

  const approveSubmit =async()=>{    
    
    const payload = {
      id:state.id,
      status:1
    }
    const response = await doPost(endpoint.approval, payload,"approve approval",getDefaultHeader())
    
    if(response && !response.error){
      getapprovals()
      handleClose()
    }
  }

  

  const rejectConfirmation =(e)=>{    
    e.stopPropagation();
    setopenDialog(true)
    setdialogContent(
      <ApprovalForm 
      action = {rejectApproval}
      closeDialog={closeDialog}
      approvalDetailChange = {handleChange}
      />
    )    
  }



  const approveConfirmation =(e)=>{    
    e.stopPropagation();
    setopenDialog(true)
    setdialogContent(
      <ApprovalForm 
      action = {approveApproval}
      closeDialog={closeDialog}
      approvalDetailChange = {handleChange}
      />
    )    
  }


  useEffect(() => {
    if(selectedApproval){
      setstate({
        ...selectedApproval,
        reason:""
      })
    }
    
  }, [selectedApproval])

  useEffect(() => {
    //console.log(state);
    
  }, [state])


  if(state){
    return (
      <>
      <DialogContent>
      <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
        <Grid container justify="flex-end" spacing={1}>  
        <Protected user={user} allowedCodes="emp">        
          <Conditional condition={state.status===0}>
            <Grid container justify="center" item xs={6} md={2}>                     
              <Button onClick={rejectSubmit} color="secondary" variant="contained">
                Reject
              </Button>
            </Grid>
            <Grid container justify="center" item xs={6} md={2}>                                        
              <Button onClick={approveSubmit} variant="contained" style={{color:'white',backgroundColor:'green'}}>
                Approve
              </Button>                 
              </Grid>                               
          </Conditional>         
        </Protected>
        </Grid>


        <Grid container className={classes.marginTop16}>  
          <Grid item xs={4} md={2}>            
            Action
          </Grid>
          <Grid item xs={8}  md={10}>   
            {state.action}          
          </Grid>          
        </Grid>

        <Grid container>  
          <Grid item xs={4} md={2}>            
            created by
          </Grid>
          <Grid item xs={8}  md={10}>   
            {state.creator && state.creator.name}          
          </Grid>          
        </Grid>

        <Grid container>  
          <Grid item xs={4} md={2}>            
            created at
          </Grid>
          <Grid item xs={8}  md={10}>   
          {state.created_at && format(state.created_at, 'yyyy-MM-dd')}
          </Grid>          
        </Grid>

        <Grid container>  
          <Grid item xs={4} md={2}>            
            decided at
          </Grid>
          <Grid item xs={8}  md={10}>   
          {state.decided_at && format(state.decided_at, 'yyyy-MM-dd')}
          </Grid>          
        </Grid> 

        <Grid container >
          <Grid item xs={4} md={2}>            
            Status
          </Grid>
          <Grid item xs={8}  md={10}>   
          <ApprovalChip status={state.status}/>          
          </Grid>          
        </Grid> 

        <Grid container style={{marginTop:16}}>  
          <Grid item xs={4} md={2}>            
            Approval detail
          </Grid>
          <Grid item xs={12}>   
            <TextField 
            id="Description"
            label=""
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={state.note}

            />
              
          </Grid>          
        </Grid> 

        <Grid container style={{marginTop:16}}>  
          <Grid item xs={4} md={2}>            
            Notes from approver
          </Grid>
          <Grid item xs={12}>   
            <TextField 
            id="Notes"
            label=""
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={state.approverNote}

            />
              
          </Grid>          
        </Grid> 
                    
      </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color="primary" variant="contained">
        Back
      </Button>      
    </DialogActions>

    <Dialog
    fullWidth={true}
    maxWidth={'sm'}
    open={openDialog}
    onClose={closeDialog}
    aria-labelledby="max-width-dialog-title"
    >        
      {dialogContent}
    </Dialog>
    </>
    );
  }
  else{
    return null
  }

}

const useStyles = makeStyles((theme) => ({
    marginTop16:{
      marginTop:16  
    },      
    
    
  }));


export default ApprovalDetail;
