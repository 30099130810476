import axios from 'axios'
//import returnStoreAndPersistor from 'reduxs/store'
import store from 'reduxs/store'
import {loading,showSnackbar} from 'reduxs/actions'

//const { store } = stores()


let host =  "";

if(process.env.REACT_APP_BACKEND_MODE ==='PROD'){
  host = process.env.REACT_APP_API_PROD
}
else if(process.env.REACT_APP_BACKEND_MODE ==='UAT'){
  host = process.env.REACT_APP_API_UAT
}
else{
  host = process.env.REACT_APP_API_LOCAL
}


export const api_host = host

export const doGetExternalApi = async(url,params={},headers={}) =>{
  store.dispatch(loading(true))
  return await axios.get(url,{
        params:params,
        headers: headers
      })
      .then((rsp)=>{
        if(rsp.data.error){
            store.dispatch(showSnackbar('error',rsp.data.message))
            console.log(rsp.data)
        }else{
            return{data:rsp.data}
        }        
        })
      .catch((error)=>{
        console.log(url)
        console.log(error)
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('error','ups something wrong in server'))
        if(error.response && error.response.data){
          return {error:error.response.data}       
        }else{
          return {error:error}       
        }

        });
}

export const doGet = async(path,params={},headers={}) =>{
  store.dispatch(loading(true))
  
  return await axios.get(api_host+path,{
    params:params,
    headers: headers
  })
      .then((rsp)=>{
        store.dispatch(loading(false))
        if(rsp.data.error){
            store.dispatch(showSnackbar('error',rsp.data.message))
            console.log(rsp.data)
        }else{
            return{data:rsp.data}
        }
        })
      .catch((error)=>{
        console.log(path)
        console.log(error)
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('error','ups something wrong in server'))
        if(error.response && error.response.data){
          return {error:error.response.data}       
        }else{
          return {error:error}       
        }
        });
}


export const doPost = async(path,payload,activity,headers={}) =>{
  store.dispatch(loading(true))
  return await axios({
        method: 'post',
        url: api_host+path,
        data: payload,
        headers: {...headers, "Content-Type": "application/json"}
      })
      .then((rsp)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('success',activity+' success'))
        return {data:rsp.data}})
      .catch((error)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('error',activity+' error'))
        console.log(error)
        if(error.response && error.response.data){
          return {error:error.response.data}       
        }else{
          return {error:error}       
        }
      })
}

export const doSilentPost = async(path,payload, headers={}) =>{
  store.dispatch(loading(true))
  return await axios({
        method: 'post',
        url: api_host+path,
        data: payload,
        headers: headers
      })
      .then((rsp)=>{
        store.dispatch(loading(false))
        return {data:rsp.data}})
      .catch((error)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('error','posting error'))
        console.log(error)
      })
}


export const doUpload = async(path,payload,headers={}) =>{
  return await axios({
        method: 'post',
        url: api_host+path,
        data: payload,
        headers: {...headers, "Content-Type": "multipart/form-data"}
      })
      .then((rsp)=>{
        store.dispatch(showSnackbar('success','upload to server success'))
        return rsp
      })
      .catch((error)=>{

        console.log(error)
      })
}

export const doPut = async(path,payload,activity,headers={}) =>{
  store.dispatch(loading(true))
  return await axios({
        method: 'put',
        url: api_host+path,
        data: payload,
        headers: headers
      })
      .then((rsp)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('success',activity+' success'))
        return {data:rsp.data}})
      .catch((error)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('error',activity+' error'))
        console.log(error)
        if(error.response && error.response.data){
          return {error:error.response.data}       
        }else{
          return {error:error}       
        }
        
      })
}

export const doSilentPut = async(path,payload,activity,headers={}) =>{
  store.dispatch(loading(true))
  return await axios({
        method: 'put',
        url: api_host+path,
        data: payload,
        headers: headers
      })
      .then((rsp)=>{
        store.dispatch(loading(false))        
        return {data:rsp.data}})
      .catch((error)=>{
        store.dispatch(loading(false))
        console.log(error)
        if(error.response && error.response.data){
          return {error:error.response.data}       
        }else{
          return {error:error}       
        }
      })
}

export const doPatch = async(path,payload,activity,headers={}) =>{
  store.dispatch(loading(true))
  return await axios({
        method: 'patch',
        url: api_host+path,
        data: payload,
        headers: headers
      })
      .then((rsp)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('success',activity+' success'))
        return {data:rsp.data}})
      .catch((error)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('error',activity+' error'))
        console.log(error)
      })
}

export const doDelete = async(path,payload,activity,headers={}) =>{
  store.dispatch(loading(true))
  return await axios({
        method: 'delete',
        url: api_host+path,
        data: payload,
        headers: headers
      })
      .then((rsp)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('success',activity+' success'))
        return {data:rsp.data}})
      .catch((error)=>{
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('error',activity+' error'))
        console.log(error)
      })
}

export const doDownloadPdf = async(path,params={},headers={}) =>{
  store.dispatch(loading(true))
  return await axios.get(api_host+path,{
    params:params,
    responseType: 'blob',
    headers: headers
  })
      .then((rsp)=>{
        store.dispatch(loading(false))
        const url = window.URL.createObjectURL(new Blob([rsp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        })
      .catch((error)=>{
        console.log(path)
        console.log(error)
        store.dispatch(loading(false))
        store.dispatch(showSnackbar('error','ups something wrong in server'))
        if(error.response && error.response.data){
          return {error:error.response.data}       
        }else{
          return {error:error}       
        }
        });
}

export const doDownloadExcel = async (path, params = {}, headers = {}) => {
  store.dispatch(loading(true));

  try {
    const rsp = await axios.get(api_host + path, {
      params: params,
      responseType: 'blob', // Pastikan responseType adalah 'blob' untuk file binary
      headers: headers,
    });

    store.dispatch(loading(false));

    // Periksa Content-Type dalam header respons
    const contentType = rsp.headers['content-type'];
    console.log('Content-Type:', contentType); // Tambahkan log untuk melihat tipe konten

    // Jika Content-Type sesuai dengan file Excel
    if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const url = window.URL.createObjectURL(new Blob([rsp.data]));
      const link = document.createElement('a');
      link.href = url;
      
      const fileName = params.type ? `${params.type}.xlsx` : 'summary.xlsx';

      // Mengatur atribut 'download' dengan nama file yang dinamis
      link.setAttribute('download', fileName);

      // link.setAttribute('download', 'summary.xlsx'); // Nama file Excel yang diunduh
      document.body.appendChild(link);
      link.click();

      return { success: true }; // Mengembalikan status sukses
    } else {
      // Jika file bukan Excel, tampilkan pesan error
      store.dispatch(showSnackbar('error', 'File yang diunduh bukan Excel.'));
      return { success: false, message: 'File bukan Excel' }; // Mengembalikan status gagal
    }
  } catch (error) {
    store.dispatch(loading(false));
    store.dispatch(showSnackbar('error', 'Ups, terjadi kesalahan pada server'));
    console.error(error);
    return { success: false, error: error.message || error }; // Mengembalikan status gagal
  }
};
