import React,{useState, useEffect} from 'react';
import {doGet, doPost, doDelete, doPut} from 'utils/apiUtil.js'
import {endpoint} from 'utils/constants.js'
import { Redirect,useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProductJenjang, setProductKelas, setProductJenjangOptions,setProductKelasOptions } from "reduxs/actions";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import numeral from 'numeral'
import { generalListOptionMapper, jenjangListOptionMapper } from 'utils/mappers.js';


const UserProductList =(props)=> {

    let { path, url } = useRouteMatch();
    const dispatch = useDispatch();
    let history = useHistory();
    const classes = useStyles();
    const user = useSelector(state => state.user);
    const category = useSelector(state => state.category);    
    const [products, setproducts] = useState([]);
    const selectedJenjang = useSelector(state => state.product_jenjang);
    const selectedKelas = useSelector(state => state.product_kelas);
    const jenjangOptions = useSelector(state => state.product_jenjang_options);
    const kelasOptions = useSelector(state => state.product_kelas_options);
    

    const setselectedJenjang =(jenjang)=>{
      dispatch(setProductJenjang(jenjang))
    }

    const setselectedKelas =(kelas)=>{
      dispatch(setProductKelas(kelas))
    }

    const setjenjangOptions =(data)=>{
      dispatch(setProductJenjangOptions(data))
    }

    const setkelasOptions =(data)=>{
      dispatch(setProductKelasOptions(data))
    }

    const getDefaultHeader =()=>{
      return {"Authorization":`Bearer ${user.token}`}
    }

    const getJenjangOptions =async()=>{
      const params={}
          
      const response = await doGet(endpoint.jenjang_options, params, getDefaultHeader());        
      if(!response.error){
        setjenjangOptions(jenjangListOptionMapper(response.data))      
      }
    }   

    const getKelasOptions =async(jenjangId)=>{
    
      const params={
        stage_id:jenjangId
      }
          
      const response = await doGet(endpoint.user_kelas_options, params, getDefaultHeader());        
      if(!response.error){
        setkelasOptions(generalListOptionMapper(response.data))      
      }
    }

    const chooseJenjang =async(jenjang)=>{
     
      setselectedKelas(null)
      setkelasOptions([])
      let newJenjang = null

     if(selectedJenjang && jenjang){
        if(selectedJenjang.id===jenjang.id){
          newJenjang = null
        }else{
          newJenjang = jenjang
        }    
      }else{
        newJenjang = jenjang
      }
      
      setselectedJenjang(newJenjang)
      
      if(newJenjang){
        getKelasOptions(newJenjang.id)    
      }
      
     
  }

  const chooseKelas =async(kelas)=>{
    /*
    let params = {          
    }
    let headers = {"Authorization":`Bearer ${user.token}`}
    const response = await doGet(endpoint.jenjangs, params, headers)
    if(response && response.data && response.data.data){
      
    }
    */
   if( kelas && selectedKelas){
      if(selectedKelas.id===kelas.id){
        setselectedKelas(null)
      }else{
        setselectedKelas(kelas)
      }     
   }else{
      setselectedKelas(kelas)        
   }
    
  }  

  const getProducts =async()=>{    

    const params={
      type:'user',
      category_id : category?category.id:null,
      stage_id :selectedJenjang ? selectedJenjang.id : null,
      class_id :selectedKelas ? selectedKelas.id : null
    }
    
    const response = await doGet(endpoint.product, params, getDefaultHeader());        
    if(!response.error){
      setproducts(
        response.data
        )      
    }
        
  }   

  const back =()=>{
    history.goBack()
  }

  const getTitle =(title)=>{
    return title.length < 20? title : title.substring(0, 20)+"...";
  }   


  const gotoDetail =(id)=>{
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
    localStorage.setItem('scrollPosition', scrollPosition);
    localStorage.setItem('clickedProduct', id);

    history.push(`${url}/${id}`)
  }
  
  const getJenjangChipVariant =(jenjang)=>{
    if(jenjang==="all"){
      if(selectedJenjang){
        return "outlined"
      }else{        
        return "default"
      }
    }else{
      if(selectedJenjang){
        if(selectedJenjang.id===jenjang.id){
          return "default"
        }else{
          return "outlined"
        }
      }else{
        return "outlined"
      }
    }
    
  }

  const getKelasChipVariant =(kelas)=>{
    if(kelas==="all"){
      if(selectedKelas){
        return "outlined"
      }else{        
        return "default"
      }
    }else{
      if(selectedKelas){
        if(selectedKelas.id===kelas.id){
          return "default"
        }else{
          return "outlined"
        }
      }else{
        return "outlined"
      }
    }        
  }

  const getProductPrice =(product)=>{
    
    if(product.sizeType===1){
      return numeral(product.prices[0].price).format('0,0')
    }else{
      return numeral(product.prices[0].price).format('0,0') +" - "+ numeral(product.prices[product.prices.length - 1].price).format('0,0')
    }
  }

  


  useEffect(() => {
    if(jenjangOptions && jenjangOptions.length===0){
      getJenjangOptions()
    }
    
    getProducts()
    
    
  }, [category])

  useEffect(() => {
    getProducts()        
  }, [selectedJenjang, selectedKelas])

  
  useEffect(() => {
    if (products.length > 0) {
      const clickedProduct = localStorage.getItem('clickedProduct');
  
      if (clickedProduct) {
        const element = document.getElementById(`product-${clickedProduct}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        localStorage.removeItem('clickedProduct');
      }
    }
  }, [products]);

  if(user && user.token){
    return (
      <React.Fragment>
        <CssBaseline/>
        <Grid container alignItems='flex-start' alignContent="flex-start"  className={classes.root}>
            <Grid item xs={2} md={1}>              
                <IconButton color="primary" aria-label="go back" component="span" onClick={back}>
                  <ArrowBack />
                </IconButton>              
            </Grid>
            
            <Grid item xs={10} md={11} container justify="center" alignItems='flex-start'>
              <Grid item xs={12} container justify="flex-start" alignItems='flex-start' className={classes.chipWrapper}>
                    <Chip
                      label="Semua Jenjang"
                      clickable
                      color="primary"                      
                      variant={getJenjangChipVariant("all")}
                      className={classes.chip}
                      onClick={()=>chooseJenjang(null)}
                    />
                  {
                      jenjangOptions.map(jenjang=>(
                        <Chip
                        key={jenjang.id}
                        label={jenjang.name}
                        clickable
                        color="primary"                      
                        variant={getJenjangChipVariant(jenjang)}
                        className={classes.chip}
                        onClick={()=>chooseJenjang(jenjang)}
                      />
                      ))
                  }                    
              </Grid>

              <Grid item xs={12} container justify="flex-start" alignItems='flex-start' className={classes.chipWrapper}>
                    <Chip
                      label="Semua Kelas"
                      clickable
                      color="primary"                      
                      variant={getKelasChipVariant("all")}
                      className={classes.chip}
                      onClick={()=>chooseKelas(null)}
                    />
              {
                      kelasOptions.map(kelas=>(
                        <Chip
                        key={kelas.id}
                        label={kelas.name}
                        clickable
                        color="primary"
                        variant={getKelasChipVariant(kelas)}
                        className={classes.chip}
                        onClick={()=>chooseKelas(kelas)}
                      />
                      ))
                  }                    
              </Grid>
            </Grid>
            

            <Grid container spacing={2} justify='center' alignItems='flex-start' alignContent="flex-start" className={classes.productContainer}>
                {
                    products.map(product=>(
                        <Grid item xs={6} md={4} lg={3} id={`product-${product.id}`}>
                        <Card key={product.id} onClick={()=>gotoDetail(product.id)}>
                            <CardActionArea >
                                <CardMedia
                                className={classes.category_media}
                                image={product.cover}
                                title={product.name}
                                />
                                <CardContent>
                                <Typography variant="body2">
                                    {getTitle(product.name)}
                                </Typography>                                
                                </CardContent>
                            </CardActionArea>  
                            <CardActions>
                              <Typography variant="h6" component="h2">
                                    Rp. {numeral(product.prices[0].price).format('0,0')}
                                </Typography> 
                            </CardActions>                          
                        </Card>
                        </Grid>
                    ))
                }                    
            </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  else{
    return <Redirect to={{ pathname: '/login' }} />
  }

}

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(1)
    },
    chip:{
      margin:4
    },
    chipWrapper:{
      marginTop:8
    },
    productContainer:{
      marginTop:16
    },
    category_media: {
      width:'100%',
      paddingTop:'60%'
    }
  }));


export default UserProductList;
